<template>
  <div>
    <b-modal id="print-settings" hide-footer @hide="onHide" @shown="onShow">
      <template #modal-title>
        Print Settings
      </template>
      <div style="font-style: italic"><b>Tip:</b> Set your default template from your <b-link to="/profile">profile</b-link>!</div>
      <b-form v-on:submit.prevent="onPrint">
      <b-input-group prepend="Width" class="mt-3">
        <b-form-input ref="width" type="number" v-model="width"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Height" class="mt-3">
        <b-form-input type="number" v-model="height"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Margin Top" class="mt-3">
        <b-form-input type="number" v-model="margintop"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Margin Bottom" class="mt-3">
        <b-form-input type="number" v-model="marginbottom"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Margin Left" class="mt-3">
        <b-form-input type="number" v-model="marginleft"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Margin Right" class="mt-3">
        <b-form-input type="number" v-model="marginright"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Barcode Type" class="mt-3">
        <b-form-select v-model="barcodetype" :options="barcodetypes"></b-form-select>
      </b-input-group>
      <b-input-group prepend="Barcode Width" class="mt-3">
        <b-form-input type="number" v-model="barcodewidth"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Barcode Height" class="mt-3">
        <b-form-input type="number" v-model="barcodeheight"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Font Size" class="mt-3">
        <b-form-input type="number" v-model="fontsize"></b-form-input>
      </b-input-group>

      <b-button ref="submit" class="mt-3" size="lg" type="submit" variant="outline-primary" block>Print</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { boxesService, printService } from '@/services';
import printJS from 'print-js'

export default {
  name: 'PrintSettings',
  props: [
    'box','template'
  ],
  data() {
    return {
      width: localStorage.getItem('pagewidth') || 500,
      height: localStorage.getItem('pageheight') || 500,
      margintop: localStorage.getItem('pagemargintop') || 10,
      marginbottom: localStorage.getItem('pagemarginbottom') || 10,
      marginleft: localStorage.getItem('pagemarginleft') || 10,
      marginright: localStorage.getItem('pagemarginright') || 10,
      barcodetype: localStorage.getItem('barcodetype') || 'qr',
      barcodewidth: localStorage.getItem('barcodewidth') || 200,
      barcodeheight: localStorage.getItem('barcodeheight') || 200,
      fontsize: localStorage.getItem('pagefontsize') || 14,
      barcodetypes: [
        { value: 'datamatrix', text: 'Datamatrix' },
        { value: 'qr', text: 'QR Code' },
        { value: 'pdf417', text: 'PDF 417' },
        { value: 'code39', text: 'Code 39' },
        { value: 'code128', text: 'Code 128' },
      ]
    }
  },
  watch: {
    box() {
      if(this.box) {

        // Check if user has set a default template for printing
        if(this.template != null && this.template != '') {

          // Get template parameters
          printService.getById(this.template)
            .then(settings => {

              if(settings == '') {

                this.$bvToast.toast(`Could not get template settings, is your default template missing?`, {
                  title: 'Error',
                  autoHideDelay: 4000,
                  variant: 'danger'
                })

                this.$bvModal.show('print-settings')
                return
              }

              // Trigger print with settings for this template
              boxesService.print(this.box.id, settings)
                .then(response => {
                  let blob = new Blob([response], { type: 'application/pdf' })
                  printJS({printable: window.URL.createObjectURL(blob), type: 'pdf', base64: false})
                })
                .catch(error => {
                  this.$bvToast.toast(`${error}`, {
                    title: 'Error',
                    autoHideDelay: 4000,
                    variant: 'danger'
                  })
                })
                .then(() => {
                  // Callback onHide manually since we haven't opened the modal dialog
                  this.onHide()
                })

            })
            .catch(error => {
              this.$bvToast.toast(`${error}`, {
                title: 'Error',
                autoHideDelay: 4000,
                variant: 'danger'
              })              
            })

        } else {

          // Let user select properties for printout
          this.$bvModal.show('print-settings')

        }
      }
    },
  },
  methods: {
    onHide() {
      this.$emit('hide')
    },
    onShow() {
      // Select text when settings focus to first parameter
      this.$refs.width.$el.type = 'text'
      this.$refs.width.$el.setSelectionRange(0, this.$refs.width.$el.value)
      this.$refs.width.$el.type = 'number'
      this.$refs.width.$el.focus()
    },
    onPrint() {

      // Save user selected properties to local storage
      localStorage.setItem('pagewidth', parseInt(this.width))
      localStorage.setItem('pageheight', parseInt(this.height))
      localStorage.setItem('pagemargintop', parseInt(this.margintop))
      localStorage.setItem('pagemarginbottom', parseInt(this.marginbottom))
      localStorage.setItem('pagemarginleft', parseInt(this.marginleft))
      localStorage.setItem('pagemarginright', parseInt(this.marginright))
      localStorage.setItem('barcodetype', this.barcodetype)
      localStorage.setItem('barcodewidth', parseInt(this.barcodewidth))
      localStorage.setItem('barcodeheight', parseInt(this.barcodeheight))
      localStorage.setItem('pagefontsize', parseInt(this.fontsize))

      // Create our print properties object
      let settings = {
        printtype: 'pdf',
        pagetype: 'custom',
        pagewidth: parseInt(this.width),
        pageheight: parseInt(this.height),
        pagemargintop: parseInt(this.margintop),
        pagemarginbottom: parseInt(this.marginbottom),
        pagemarginleft: parseInt(this.marginleft),
        pagemarginright: parseInt(this.marginright),
        barcodetype: this.barcodetype,
        barcodewidth: parseInt(this.barcodewidth),
        barcodeheight: parseInt(this.barcodeheight),
        fontsize: parseInt(this.fontsize)
      }

      // Trigger printout with user selected properties
      boxesService.print(this.box.id, settings)
        .then(response => {
          let blob = new Blob([response], { type: 'application/pdf' })
          printJS({printable: window.URL.createObjectURL(blob), type: 'pdf', base64: false})
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
        .then(() => {
          this.$bvModal.hide('print-settings')
        })
    }
  }
}
</script>