<template>
  <div class="boxes">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='history'" v-on:click="tab='history'">History</b-nav-item>
      <b-nav-item :active="tab=='current'" v-on:click="tab='current'" v-if="current != ''">{{ current }} </b-nav-item>
    </b-nav>

    <boxestable :visible="tab=='history'" :update="updateHistory" @edit="onEditBox" @create="onCreateBox" @updated="triggerUpdate"></boxestable>
    <box :visible="tab!='history'" :boxid="loadedBoxId" @confirm="onConfirmBox"></box>

  </div>
</template>

<script>
import boxestable from '@/components/manufacturing.boxes/history'
import box from '@/components/manufacturing.boxes/box'

export default {
  name: 'Boxes',
  components: {
    box,
    boxestable
  },
  data () {
    return {
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Manufacturing',
          to: '/manufacturing'
        },
        {
          text: 'Boxes',
          active: true
        }
      ],
      tab: '',
      current: '',
      loadedBoxId: '',
      updateHistory: false,
    }
  },
  watch: {
    tab() {
      if(this.tab == 'history') {
        this.loadedBoxId = ''
        this.current = ''
      }
    }
  },
  methods: {
    triggerUpdate() {
      this.updateHistory = false
    },
    onEditBox(data) {
      this.tab = 'current' 
      this.loadedBoxId = data.id
      this.current = data.boxidentification
    },
    onCreateBox(data) {
      this.tab = 'current' 
      this.loadedBoxId = data.id
      this.current = data.boxidentification
      this.$bvToast.toast(`Created a box successfully`, {
        title: 'Success',
        autoHideDelay: 4000,
        variant: 'success'
      })
      this.updateHistory = true;
    },
    onConfirmBox(data) {
      this.tab = 'current'
      this.loadedBoxId = data.id
      this.current = data.boxidentification
      this.updateHistory = true;
    }
  },
  async created() {
    this.tab = 'history'
  }
}
</script>
