<template>
  <div :hidden="!visible">
    <div class="row mb-3">
      <div class="col">
        <b-button-group>
          <b-button variant="outline-primary" v-on:click="loadData"><b-icon-arrow-repeat></b-icon-arrow-repeat></b-button>
          <b-button variant="outline-primary" v-on:click="onCreate"><b-icon-plus></b-icon-plus> Create</b-button>
        </b-button-group>
      </div>
      <div class="col-lg-4 col-md-12">
        <b-form v-on:submit.prevent="loadData">
          <b-input-group>
            <b-input type="text" ref="search" placeholder="Search..." v-model="search" />
            <b-input-group-append>
              <b-button size="sm" text="Button" variant="outline-primary" v-on:click="loadData"><b-icon-search></b-icon-search></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table id="main-table" striped hover :items="items" :fields="fields" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(boxidentification)="row">
            <span class="link text-primary" v-on:click="onEdit(row)">
              <b-icon-check-circle-fill variant="success" v-if="row.item.confirmed == true" class="mr-1"></b-icon-check-circle-fill>
              <b-spinner variant="secondary" small v-if="row.item.confirmed == false" class="mr-1"></b-spinner>
              {{ row.item.boxidentification }}
            </span>
          </template>
          <template #cell(createddate)="row">
            {{ row.item.createddate | moment('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template #cell(units)="row">
            <b-badge pill variant="secondary">{{ row.item.items.length }}</b-badge>
          </template>
          <template #cell(action)="row">
            <a href="#" v-if="row.item.confirmed" v-on:click.prevent="onPrint(row)"><b-icon-printer></b-icon-printer></a>
            <a v-if="isAdmin || account.userName == row.item.lastchangedby" class="ml-3" href="#" v-on:click.prevent="onDelete(row)"><b-icon-trash></b-icon-trash></a> 
          </template>
        </b-table>
      </div>
    </div>

    <printsettings v-if="visible" :box="printIem" :template="printTemplate" @hide="onPrintFinish"></printsettings>

    <div class="row">
      <div class="col">
        <span>
        Showing {{ ((currentPage - 1) * currentPageSize) + 1 }} to {{ activeRows() }} of {{ totalItems }} rows 
        <b-dropdown variant="outline-primary" :text="(currentPageSize).toString()" v-model="currentPageSize">
          <b-dropdown-item v-on:click="currentPageSize=10">10</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=20">20</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=50">50</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=100">100</b-dropdown-item>
        </b-dropdown>
        rows per page</span>
      </div>
      <div class="col">
        <b-pagination align="right" v-if="!isBusy" v-model="currentPage" :per-page="currentPageSize" :total-rows="totalItems" aria-controls="main-table"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { boxesService } from '@/services';
import printsettings from './box.print.dialog'
import { auth, hasRole } from "@/helpers/auth";
import { tablePagination } from '@/mixins/table-pagination'

export default {
  name: 'BoxesTable',
  components: {
    printsettings
  },
  props: [
    'visible',
    'update'
  ],
  mixins: [
    tablePagination
  ],
  data () {
    return {
      fields: ['boxidentification', 'createddate', 'lastchangedby', 'units', { key: 'action', label: '', tdClass: "text-right" }],
      search: '',
      isBusy: false,
      printIem: null,
      printTemplate: null,
      interval: null,
      isAdmin: false,
      account: { name: '' },
    }
  },
  watch: {
    'update'() {
      if(this.update) {
        this.loadData();
      }
    },
    currentPage() {
      this.loadData()
    },
    currentPageSize() {
      this.loadData()
    }
  },
  methods: {
    onCreate() {
      boxesService.createBox()
        .then(response => {
          this.$emit('create', response)
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
    },
    onEdit(row) {
      this.$emit('edit', row.item)
    },
    async onDelete(row) {
      let answer = await this.$bvModal.msgBoxConfirm(`This will delete box: ${row.item.boxidentification}`, { title: 'Are you sure?', okTitle: 'Yes', cancelTitle: 'No', okVariant: 'primary', cancelVariant: 'outline-primary' })

      if(answer) {
        boxesService.deleteBox(row.item.id)
          .then(response => {
            this.$bvToast.toast(`The box has been deleted successfully`, {
              title: 'Success',
              autoHideDelay: 4000,
              variant: 'success'
            })
            this.loadData()          
          })
          .catch(error => {
            this.$bvToast.toast(`${error}`, {
              title: 'Error',
              autoHideDelay: 4000,
              variant: 'danger'
            })
          })
      }
    },
    onPrintFinish() {
      this.printIem = null
    },
    onPrint(row) {
      this.printIem = row.item
    },
    loadData() {
      this.isBusy = true
      boxesService.getAll(this.search, this.currentPageSize, this.currentPage)
        .then(data => {
          this.totalItems = data.count
          this.items = data.data
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
        .then(() => {
          this.isBusy = false
          this.$refs.search.$el.focus()
          this.$emit('updated')
        })
    }
  },
  async mounted() {

    this.printTemplate = localStorage.getItem('printtemplate');
    this.isAdmin = await hasRole(['Manufacturing.Admin'])
    this.account = auth.getAccount()
    this.loadData()

    // Load new data each 5 min
    this.interval = setInterval(function () {
      this.loadData();
    }.bind(this), 60000 * 5);
    
  },
  beforeDestroy: function() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
body {
  text-align: left !important;
}
.link {
  cursor: pointer;
}
</style>