<template>
  <div :hidden="!visible">
    <div class="row mb-3" v-if="info">
      <div class="col">
        <b-card v-if="!info.confirmed" bg-variant="light" :border-variant="info.confirmed ? 'success' : 'default'" no-body class="px-3">
          <div class="row">
            <div class="col-lg-8 my-auto">
              <b-card-text class="p-0">
                <b-form>
                  <b-input-group style="background: #fff">
                    <b-input-group-prepend>
                      <b-button v-if="(totalItems ? totalItems : 0) == 0" disabled variant="outline-primary">
                        <b-icon-lock></b-icon-lock>
                      </b-button>
                      <b-button v-if="(totalItems ? totalItems : 0) != 0" variant="outline-primary" v-on:click="articleLock">
                        <b-icon-unlock v-if="articleLocked"></b-icon-unlock>
                        <b-icon-lock v-if="!articleLocked"></b-icon-lock>
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input class="border-primary" :disabled="info.confirmed || articleLocked" ref="articlenumber" placeholder="Articlenumber" v-model="articlenumber" v-on:keyup.enter="onEnterArticleNumber"></b-form-input>
                    <b-form-input class="border-primary" :disabled="info.confirmed" ref="serialnumber" placeholder="Serialnumber" v-model="serialnumber" v-on:keyup.enter="onEnterSerialNumber"></b-form-input>
                    <b-input-group-append>
                      <b-button-group>
                        <b-button v-if="!info.confirmed" type="button" variant="outline-primary" v-on:click.prevent="addUnit"><b-icon-plus></b-icon-plus> Add</b-button>
                        <b-button v-if="!info.confirmed" variant="outline-primary" v-on:click="onConfirm"><b-icon-check2></b-icon-check2></b-button>
                      </b-button-group>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
              </b-card-text>
            </div>
            <div class="col-lg-4 text-right my-auto">
              <span :class="counterclass">{{ totalItems ? totalItems : 0 }}</span>
            </div>
          </div>
        </b-card>

        <b-card v-if="info.confirmed" no-body class="px-3" style="background: #dcedc8" :border-variant="info.confirmed ? 'success' : 'default'">
          <div class="row">
            <div class="col-lg-7 my-auto">
              <b-card-text class="p-0">

                <b-form style="background: #fff" v-if="isAdmin">

                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button v-if="(totalItems ? totalItems : 0) == 0" disabled variant="outline-primary">
                        <b-icon-lock></b-icon-lock>
                      </b-button>
                      <b-button v-if="(totalItems ? totalItems : 0) != 0" variant="outline-primary" v-on:click="articleLock">
                        <b-icon-unlock v-if="articleLocked"></b-icon-unlock>
                        <b-icon-lock v-if="!articleLocked"></b-icon-lock>
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input class="border-primary" :disabled="articleLocked" ref="articlenumber" placeholder="Articlenumber" v-model="articlenumber" v-on:keyup.enter="onEnterArticleNumber"></b-form-input>
                    <b-form-input class="border-primary" ref="serialnumber" placeholder="Serialnumber" v-model="serialnumber" v-on:keyup.enter="onEnterSerialNumber"></b-form-input>
                    <b-input-group-append>
                      <b-button v-if="info.confirmed" type="button" variant="outline-primary" v-on:click.prevent="addUnit"><b-icon-plus></b-icon-plus></b-button>
                      <b-button variant="primary" v-on:click="onPrint"><b-icon-printer></b-icon-printer></b-button>
                    </b-input-group-append>
  
                  </b-input-group>

                </b-form>

                <div v-if="!isAdmin">
                  <b-button v-if="info.confirmed" variant="primary" size="lg" v-on:click="onPrint"><b-icon-printer></b-icon-printer> Print Label</b-button>
                  <b-icon-lock-fill class="ml-3"></b-icon-lock-fill> Box has been confirmed!
                </div>

              </b-card-text>
            </div>
            <div class="col-lg-3 text-left my-auto px-0">
              <span v-if="isAdmin"><b-icon-lock-fill></b-icon-lock-fill> Box has been confirmed!</span>
            </div>
            <div class="col-lg-2 text-right my-auto">
              <span class="counter">{{ totalItems ? totalItems : 0 }}</span>
            </div>
          </div>
        </b-card>

        <printsettings v-if="visible" :box="printItem" :template="printTemplate" @hide="onPrintFinish"></printsettings>

      </div>
    </div>
    
    <div class="row">
      <div class="col">
        <b-table id="main-table" striped hover :items="items" :fields="fields" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(createddate)="row">
            {{ row.item.createddate | moment('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template #cell(action)="row">
            <a href="#" v-if="!info.confirmed || isAdmin" v-on:click.prevent="onDelete(row)"><b-icon-trash></b-icon-trash></a>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span>
        Showing {{ ((currentPage - 1) * currentPageSize) + 1 }} to {{ activeRows() }} of {{ totalItems }} rows 
        <b-dropdown variant="outline-primary" :text="(currentPageSize).toString()" v-model="currentPageSize">
          <b-dropdown-item v-on:click="currentPageSize=10">10</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=20">20</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=50">50</b-dropdown-item>
          <b-dropdown-item v-on:click="currentPageSize=100">100</b-dropdown-item>
        </b-dropdown>
        rows per page</span>
      </div>
      <div class="col">
        <b-pagination align="right" v-if="!isBusy" v-model="currentPage" :per-page="currentPageSize" :total-rows="totalItems" aria-controls="main-table"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { hasRole } from "@/helpers/auth";
import { boxesService } from '@/services';
import printsettings from './box.print.dialog'
import { tablePagination } from '@/mixins/table-pagination'

export default {
  name: 'Box',
  components: {
    printsettings
  },
  props: [
    'boxid',
    'visible'
  ],
  mixins: [
    tablePagination
  ],
  data () {
    return {
      info: null, // Box information from Data Response
      fields: ['articlenumber', 'serialnumber', 'createddate', 'lastchangedby', { key: 'action', label: '', tdClass: "text-right" }],
      isBusy: false, // Data Loading State
      articlenumber: '', // Form Input
      serialnumber: '', // Form Input
      counterclass: 'counter text-muted',
      printItem: null, // Trigger printout dialog
      printTemplate: null, // Print template object
      disableinput: false, // Disable/enable input during processing
      articleLocked: false, // Lock/Disable Articlenumber input field
      isAdmin: false, // Enable admin actions
    }
  },
  watch: {
    boxid() {
      if(this.boxid) {
        this.disableinput = false
        this.articleLocked = false
        this.articlenumber = ''
        this.loadData()
      }
      else { 
        this.totalItems = null
        this.items = []
        this.info = {}
        this.counterclass = 'counter text-muted'
      }
    },
    currentPage() {
      this.loadUnits()
    },
    currentPageSize() {
      this.loadUnits()
    }
  },
  methods: {
    onEnterArticleNumber(e) {
      this.$refs.serialnumber.$el.focus()
    },
    onEnterSerialNumber(e) {
      this.addUnit()
    },
    articleLock() {
      if((this.totalItems ? this.totalItems : 0) == 0) {
        this.articlenumber = ''
        this.articleLocked = false;
        this.$nextTick(() => {
          this.$refs.articlenumber.$el.focus()
        })
      } else {
        if(this.articleLocked) {
          this.articlenumber = ''
          this.articleLocked = false;
          this.$nextTick(() => {
            this.$refs.articlenumber.$el.focus()
          })
        } else {
          this.articlenumber = this.items[0].articlenumber
          this.articleLocked = true;
          this.$refs.serialnumber.$el.focus()
        }
      }
    },
    async loadData() {
      await boxesService.getById(this.boxid)
      .then(response => {
        this.info = response
        this.resetCounterProps()
        return this.loadUnits()
      })
      .then(response => {
        if(!this.info.confirmed)
        {
          if(this.totalItems == 0) { 
            this.articlenumber = ''; 
            this.articleLocked = false;
            this.$nextTick(() => {
              this.$refs.articlenumber.$el.focus()
            })
          } else
          if(this.articleLocked) this.$refs.serialnumber.$el.focus(); else this.$refs.articlenumber.$el.focus();
        } else {
          if(this.isAdmin) {
            if(this.articleLocked) this.$refs.serialnumber.$el.focus(); else this.$refs.articlenumber.$el.focus();
          }
        }
      })
      .catch(error => {
        this.$bvToast.toast(`${JSON.stringify(error)}`, {
          title: 'Error',
          autoHideDelay: 4000,
          variant: 'danger'
        })
      })
    },
    async loadUnits() {
      this.isBusy = true
      let response = await boxesService.getBoxUnits(this.boxid, this.currentPageSize, this.currentPage).catch(error => {
        this.$bvToast.toast(`${JSON.stringify(error)}`, {
          title: 'Error',
          autoHideDelay: 4000,
          variant: 'danger'
        })
        this.isBusy = false      
      })
      this.isBusy = false
      this.totalItems = response.count
      this.items = response.data
    },
    resetCounterProps() {
      if(!this.info.confirmed)
        this.counterclass = 'counter text-muted'
      else
        this.counterclass = 'counter text-success'
    },
    onDelete(row) {
      this.resetCounterProps()
      boxesService.removeUnitFromBox(this.boxid, row.item.id)
        .then(response => {
          this.$bvToast.toast(`The serial: ${row.item.serialnumber} has been removed from box `, {
            title: 'Success',
            autoHideDelay: 4000,
            variant: 'success'
          })
          this.counterclass = 'counter blink_me text-muted'
          setTimeout(this.resetCounterProps, 1200)
          this.loadData()
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
        .then(() => {
          this.isBusy = false
        })
    },
    onPrint() {
      this.printItem = this.info // Show printsettings component
    },
    onPrintFinish() {
      this.printItem = null // Hide printsettings component
    },
    async onConfirm() {
      let answer = await this.$bvModal.msgBoxConfirm(`This will finalize the box with the quantity of ${this.totalItems} pieces. You will not be able to edit this box after it has been confirmed.`, { title: 'Do you want to confirm this box?', okTitle: 'Yes', cancelTitle: 'No', okVariant: 'primary', cancelVariant: 'outline-primary' })

      if(answer) {
        boxesService.ConfirmById(this.boxid)
          .then(response => {
            this.$bvToast.toast(`Box: ${response.boxidentification} has been confirmed! `, {
              title: 'Success',
              autoHideDelay: 4000,
              variant: 'success'
            })
            this.info = response
            this.$emit('confirm', response)
          })
          .catch(error => {
            this.$bvToast.toast(`${error}`, {
              title: 'Error',
              autoHideDelay: 4000,
              variant: 'danger'
            })
          })
      }
    },
    addUnit() {
      // Make sure we cannot get any user input until we have processed current...
      if(this.disableinput){ return } else { this.disableinput = true }

      this.resetCounterProps()
      boxesService.addUnitToBox(this.boxid, this.articlenumber, this.serialnumber)
        .then(response => {
          if(this.items.length == this.currentPageSize)
            this.$delete(this.items, this.items.length-1)
          this.items.unshift(response)
          this.$bvToast.toast(`Serialnumber: ${this.serialnumber} has been added`, {
            title: 'Information',
            autoHideDelay: 1000,
            variant: 'info'
          })
          this.serialnumber = ''
          this.totalItems++
          this.counterclass = 'counter blink_me text-muted'
          setTimeout(this.resetCounterProps, 1200)
          if(this.articleLocked) {
            this.$refs.serialnumber.$el.focus();
          } else {
            this.articlenumber = ''
            this.$refs.articlenumber.$el.focus();
          }
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
          this.serialnumber = ''
          if(this.articleLocked) {
            this.$refs.serialnumber.$el.focus();
          } else {
            this.articlenumber = ''
            this.$refs.articlenumber.$el.focus();
          }
        })
        .then(() => {
          this.isBusy = false
          this.disableinput = false
        })
    }
  },
  async mounted() {

    this.isAdmin = await hasRole(['Manufacturing.Admin'])
    this.printTemplate = localStorage.getItem('printtemplate');

    if(this.boxid) {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.counter {
  font-size: 72px !important;
  font-weight: bold;
}

.blink_me {
  animation: pulse .75s linear;
}

@keyframes pulse {
  50% {
    opacity: 0;
  }
}
</style>
